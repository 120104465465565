a{
    color: inherit;
}
a:hover{
    color: inherit;
    text-decoration:none;
}
button{
    border: none;
    outline: none;
}
p{
    font-family: var(--font-light);
    font-size: var(--size-normal);
}
/* elements */
.header-callaction{
    border-radius: 25px;
    background: var(--color-gray-darker);
}
.btn-action{
    background: var(--color-yellow);
    color: var(--color-black);
    font-family: var(--font-light);
    font-size: var(--size-normal);
    border-radius: 25px;
    padding: 9px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn-action.login{
    font-size: 20px
}
.btn-action.gray{
    background: var(--color-gray);
    color: var(--color-black);
}
.btn-action.gray-darker{
    background: var(--color-gray-darker);
    color: var(--color-black);
}
.btn-action.gray-darker:hover{
    background: var(--color-black);
    color: var(--color-white);
}
.btn-action:hover{
    transition: 0.3s all;
}
.btn-header{
    min-width: 110px;
    position: relative;
    z-index: 5;
}
.btn-header.active{
    background: var(--color-black) !important;
    color: var(--color-white) !important;
}
.button-close-session{
    position: relative;
    z-index: 200;
}
.border-100{
    border-radius: 20px;
}
.container-input{
    margin-bottom: 12px;
    width: 100%;
    position: relative;
    display: grid;
}
.margin-top-input{
    margin-top: 10px;
}
.input-class{
    background: var(--color-gray);
    color: var(--color-black);
    font-family: var(--font-light);
    font-size: var(--size-normal);
    border-radius: 20px;
    border: none;
    outline: none;
    padding: 10px 15px;
    border: 1px solid var(--color-gray);
}
.input-class.login{
    font-size: 20px;
}
.input-class.white{
    background: var(--color-white);
}
.input-class:focus{
    box-shadow: 0px 0px 4px var(--color-gray-dark);
    border: 1px solid var(--color-gray);
}
select.input-class{
    appearance: none;
    -webkit-appearance: none;
}
.icon-select{
    position: absolute;
    width: 15px;
    right: 20px;
    top: 18px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-gray);
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: var(--color-yellow);;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px var(--color-yellow);
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  /**/
.btn-close {
    font-size: var(--size-small);
    font-family: var(--font-bold);
}
.btn-close img{
    width: 15px;
    margin-left: 10px;
}
.btn-close span{
    margin-top: 1px;
}
.title-icon{
    color: var(--color-white);
    padding: 6px 15px 6px 4px;
    font-family: var(--font-normal);
    font-size: var(--size-normal);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 20px;
}
.title-icon img{
    width: 40px;
}
.title-icon span{
    margin-left: 10px;
    margin-top: 5px;
    color: var(--color-black);
}
.title-icon.register span{
    color: var(--color-white);
}
.title-icon-continental{
    color: var(--color-black);
    padding: 0 15px 0 4px;
    font-family: var(--font-bold);
    font-size: var(--size-huge);
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.header-black .title-icon-continental{
    color: var(--color-white);
    font-size: var(--size-big);
    margin-bottom: 20px;
}
.title-icon-continental img{
    width: 110px;
}
.title-icon-continental span{
    margin-left: 10px;
}
.title-h2{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
    border-bottom: 1px solid var(--color-black);
    margin-top: 30px;
}
.title-h2.margin-top{
    margin-top: 30px;
}
.title-h2 h2{
    font-size: var(--size-normal);
    margin-right: 10px;
    font-family: var(--font-bold);
    margin-top: 0;
    margin-bottom: 0;
}
.title-h2 .line{
    background: var(--color-yellow);
    flex: 1;
    height: 3px;
    margin-top: -4px;
}
.scroll-object::-webkit-scrollbar {
    height: 10px;
    width: 5px;
    margin-top: 30px;
    border-radius: 10px;
    background: transparent;
    transition: all 0.3s ease;
    background-clip: padding-box;
  }
.scroll-object::-webkit-scrollbar:hover {
background: var(--color-gray); 
cursor: pointer;
}
.scroll-object::-webkit-scrollbar-thumb {
background: var(--color-gray);
border-radius: 4px; 
}
.changes-c{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.content-c{
    width: 32.3%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.title-modal-c{
    width: 100%;
    padding: 5px 10px;
    border-radius: 25px;
    background: #b1b1b1;
    color: var(--color-black);
    font-family: var(--font-light);
    font-size: 18px;
    text-align: center;
}
.content-c > p{
    font-family: var(--font-bold);
    font-size: 18px;
}
.img-icons-modal{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px 0 30px;
}
.icon-modal{
    width: 23%;
    margin-bottom: 10px;
}
.scroll-body{
    overflow-y: auto;
}
.tire-side-modal{
    position: sticky;
}
.icon-close-m{
    width: 20px;
}
/* */ 
.container-tire{
    margin-bottom: 20px;
    width: 25%;
    padding-left: 7px;
    padding-right: 7px;
}


.img-tire{
    width:170px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}
.img-tire-cotizador, .img-tire-modal{
    width: 100%;
    padding: 0;
}
.content-information{
    width: 100%;
    margin-top: 8px;
    display: flex;
    justify-content: center;
}
.content-information-2{
    width: 100%;
    margin-top: 8px;
    display: grid;
    justify-content: center;
}
.content-information.flex-column{
    flex-direction: column;
}
.title-information{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 11px 10px 6px 10px;
    background: var(--color-gray);
    border-radius: 20px 20px 0 0;
    max-width: 250px;
    width: 100%;
}
.title-information.padding-cotizador{
    padding: 0 15px 10px 10px;
}
.title-information.border-complete{
    border-radius: 25px;
    padding: 10px 10px 7px 10px;
}
.circle-color{
    width: 25px;
    height: 25px;
    max-width: 25px;
    max-height: 25px;
    min-width: 25px;
    min-height: 25px;
    border: 4px solid var(--color-white);
    border-radius: 100%;
    margin-top: -3px;
    margin-right: 10px;
}
.circle-color.circle-big{
    width: 27px;
    height: 27px;
}
.circle-color.yellow{
    background: var(--color-yellow);
}
.circle-color.blue{
    background: var(--color-blue);
}
.circle-color.red{
    background: var(--color-red);
}
.circle-color.green{
    background: var(--color-green);
}
.title-information span{
    font-family: var(--font-light);
    font-size: var(--size-small);
}
.cart-information{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 15px 5px 15px;
    background: var(--color-black);
    color: var(--color-white);
}
.cart-information span{
    font-family: var(--font-light);
    font-size: var(--size-small);
    margin-top: 5px;
    margin-right: 5px;
}
.cart-information.border-complete{
    border-radius: 0 0 20px 20px;
}
.cart-information .icon_cart{
    width: 25px;
}
/* modal */
.modal-background{
    width: 100%;
    height: 100%;
    position: fixed;
    background: var(--color-black-transparent);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    top: 0;
    left: 0;
}
.modal-background.disabled{
    display: none !important;
}
.modal-background.videos-modal{
    display: flex;
    align-items: center;
    justify-content: center;
}
.modal-filter{
    width: 475px;
    min-height: 90%;
    max-height: 90%;
    padding: 22px;
    background: var(--color-white);
    border-radius: 20px;
    display: grid;
    grid-template-rows: 40px 1fr 60px;
}
.modal-filter.tire-detail{
    width: 100%;
    min-height: 100%;
    max-height: 100%;
}
.modal-filter.news{
    padding: 22px 0;
    grid-template-rows: 40px 1fr;
}
.filter-small{
    min-height: 20px;
}
.modal-filter .header-modal.tire-detail{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.modal-filter .header-modal{
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}
.modal-filter .header-modal.news{
    justify-content: space-between;
    padding: 0 22px;
}
.modal-filter .header-modal.news h2{
    font-size: 24px;
    font-family: var(--font-bold);
    margin: 0;
}
.modal-filter .header-modal > button{
    width: 20px;
    padding: 0;
    background: transparent;
}
.modal-filter .body-modal{
    position: relative;
    width: 100%;
    padding-bottom: 40px;
}
.modal-filter .body-modal p{
    padding: 20px 22px;
}
.modal-filter.tire-detail .body-modal p{
    padding:10px 10px;
}
.modal-filter.filter-small .body-modal{
    padding-bottom: 10px;
}
.modal-filter .body-modal .container{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding-bottom: 20px;
    overflow-y: auto;
}
.modal-filter.no-padding{
    padding: 0;
}
.modal-filter p{
    color: var(--color-black);
}
.modal-filter .footer-modal{
    display: flex;
    align-items: flex-end;
}
.modal-filter .footer-modal > button{
    font-family: var(--font-bold);
    font-size: 18px;
}
.button-filter{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px auto;
    width: 100%;
    max-width: 100%;
    min-width: 100px;
}
.button-filter > button{
    width: 49.5%;
    background: var(--color-gray-darker);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: var(--font-bold);
    color: var(--color-black);
    padding: 15px 10px;
    transition: 0.3s all;
}
.button-filter > button.active, .button-filter > button:hover{
    background: var(--color-yellow);
}
.button-filter > button.continental{
    background: var(--color-yellow);
}
.button-filter > button.generaltire{
    background: var(--color-red);
}
.button-filter > button:first-child{
    border-radius: 40px 0 0 40px;
}
.button-filter > button:last-child{
    border-radius: 0 40px 40px 0;
}
.content-modal-button{
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
}
.content-modal-button.justify-content-between{
    justify-content: space-between;
}
.content-modal-button .btn-action{
    font-family: var(--font-bold);
}
.modal-filter.disabled{
    display: none;
}
.brand-tire{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.option-tire{
    width: 46%;
    margin: 0 2%;
}
.modal-information{
    width: 1024px;
    padding:0 40px 30px 40px;
    background: var(--color-white);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-height: 90%;
    overflow-y: auto;

}
.modal-information .header-form{
    padding-top:20px;
    padding-bottom: 15px;
    position: sticky;
    top: 0;
    z-index: 20;
} 
.modal-information.disabled{
    display: none;
}
.content-add-cart{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.icons-cart{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 15px;
}
.icons-cart a{
    width: 35px;
    margin-left: 15px;
}
.icons-cart a:hover{
    transition: 0.3s all;
    transform: scale(1.02);
}
.container-tire-cotizador{
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-bottom: 25px;
    width: 100%;
}
.container-tire-cotizador .img-tire{
    width: 125px;
}
.content-tire-cotizador{
    flex: 1;
    background: var(--color-gray);
    padding: 12px 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 20px;
}
.content-tire-cotizador.black{
    background: #000;
    margin-bottom: 70px;
    width: calc(100% - 125px);
}
.content-prices{
    flex: 1;
    margin-left: 40px;
    position: sticky;
    top: 0;
    justify-content: flex-end;
}
.text-cotizador{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 9px 25px;
    font-family: var(--font-normal);
    font-size: var(--size-normal);
}
.text-cotizador.back-yellow{
    background:var(--color-yellow);
    border-radius: 0 0 20px 20px;
}
.black .text-cotizador{
    color: var(--color-white);
    border-bottom: none;
    padding: 15px 15px;
}
.cotizador-price{
    padding: 0 15px 10px 15px;
}
.text-cotizador:last-child{
    border-bottom: none;
}
.text-cotizador .light{
    font-family: var(--font-light);
    width: 120px;
}
.line-contizador{
    border-bottom: 1px solid var(--color-black);
    flex: 1;
    text-align: right;
}
.cotizador-close{
    width: 30px;
    position: absolute;
    top: 0px;
    right: 0;
}
.button-cotizador{
    margin-bottom: 30px;
}
.container-tips{
    display: flex;
    position: relative;
    margin-bottom: 25px;
    width: 33.3%;
    padding: 0 10px;
}
.container-tips .img-tip{
    width: 40%;
    overflow: hidden;
    border-radius: 20px 0 0 20px;
}
.content-tip-text{
    flex: 1;
    border-radius:0  20px 20px 0;
    background: var(--color-gray);
    padding: 10px 10px 10px 20px;
    display: flex;
    flex-direction: column;
}
.content-tip-text .information{
    flex: 1;
}
.content-tip-text h4{
    font-family: var(--font-bold);
    font-size: var(--size-big);
}

.container-filter{
    position: relative;
}
.filters{
    display: flex;
    flex-direction: column;
}
.footer-filters{
    margin-top: 30px;
}

.field-modal.border-top{
    border-top: 1px solid var(--color-black);
    margin-top: 25px;
    padding-top: 25px;
}
.field-modal.p-green{
    margin: 25px 0;
}
.field-modal h3{
    font-size: 16px;
    width: 100%;
    font-family: var(--font-bold);
    margin-bottom: 3px
}
.container-information-filters{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}
.container-information-filters label{
    min-width: 45%;
    margin-right: 20px;
    font-size: 16px;
    font-family: var(--font-light);
    margin-bottom: 0;
}
.container-information-filters input{
    width: 100%;
    text-align: right;
    padding: 7px 15px;
}

.section-toolbox{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 0 7%;
}
.section-toolbox.center-content{
    justify-content: center;
}
.section-toolbox.flex-column{
    flex-direction: column;
}
.info-tool{
    width: 48%;
    display: flex;
    flex-direction: column;
    padding: 0 5%;
}
.info-tool img{
    margin: 0 auto 20px 0;
}
.txt-information-tool{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.txt-information-tool p{
    width: 50%;
    text-align: center;
    font-size: 19px;
}
.decriptioninformation{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.decriptioninformation label{
    min-width: 160px;
    text-align: center;
    font-size: 16px;
    font-family: var(--font-light);
    text-align: left;
    padding-right: 20px;
}
.decriptioninformation span{
    flex:1;
    border-bottom: 1px solid var(--color-black);
    padding: 0 15px 3px 0;
    font-size: 18px;
    font-family: var(--font-light);
    text-align: right;
}
.decriptioninformation.no-border span{
    border-bottom: none;
}
.measure-data{
    width: 100%;
    max-width: 900px;
    border-radius: 25px;
    font-size: 19px;
    font-family: var(--font-light);
    padding: 15px 24px;
    color: var(--color-white);
    text-align: center;
}
.measure-data.green{
    background: var(--color-green);
}
.measure-data.red{
    background: var(--color-red);
}
.section-toolbox h3{
    font-size: 19px;
    width: 100%;
    font-family: var(--font-bold);
    margin-bottom: 3px
}
.container-pdf{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 20px;
}
.container-pdf .pdf-info{
    width: 200px;
    margin-right: 15px;
    border: 5px solid var(--color-white);
}
.container-pdf .pdf-info.active{
    border: 5px solid var(--color-yellow);
    position: relative;
}
.container-pdf .pdf-info.active::after{
    position: absolute;
    content: "";
    top: 100%;
    left: 0;
    width: 100%;
    height: 20px;
    background: url("./new/select-g.svg");
    background-position: center;
    background-repeat: no-repeat;
}
.iflame-pdf{
    width: 100%;
    height: 100%;
    display: flex;
}

.container-videos{
    width: 25%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    padding: 0 1%;
}
.container-videos > img{
    width: 100%;
}
.content-information-video{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.content-information-video h4{
    font-size: 20px;
    font-family: var(--font-bold);
    margin-bottom: 4px;
}
.video-description{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    font-family: var(--font-light);
    white-space: nowrap;
    overflow-y: hidden;
}
.video-description img{
    width: 20px;
    margin-right: 15px;
}
.modal-video{
    width: 700px;
}
.modal-video{
    width: 700px;
    height: 500px;
}
#butonFilter.active{
    background:var(--color-yellow) !important;
    color: var(--color-black) !important;
}
.content-scroll{
    width: 100%;
    height: 100%;
    overflow-y: auto;
    position: absolute;
    top: 0;
    left: 0;
}
/* checkbox */
.container-checkbox{
    width: 48%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background:var(--color-gray);
    padding: 10px 20px;
    border-radius: 25px;
    margin-bottom: 10px;
}
.container-checkbox.active{
    background:var(--color-yellow);
}
.container-checkbox > span{
    margin-top: 2px;
}
.container-c {
    display: block;
    position: relative;
    padding-left: 15px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 30px;
    height: 30px;
    margin-bottom: 0;
  }
  
  /* Hide the browser's default checkbox */
  .container-c input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 30px;
    background-color: #fff;
    border-radius: 100%;
  }
  
  /* On mouse-over, add a grey background color */
  .container-c:hover input ~ .checkmark {
    background-color: #fff;
  }
  
  /* When the checkbox is checked, add a blue background */
  .container-c input:checked ~ .checkmark {
    background-color: #fff;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .container-c input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .container-c .checkmark:after {
    left: 12px;
    top: 5px;
    width: 7px;
    height: 15px;
    border: solid var(--color-yellow);
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .cart-description{
      display: flex;
      flex-direction: column;
      align-items: flex-end;
  }
  .container-up{
      position: relative;
      width: 100%;
      height: 100%;
      overflow-y: auto;
  }
  .container-button-bottom{
      display: grid;
      grid-template-rows: 1fr 60px;
      padding: 20px 0 !important;
  }
  .container-button-bottom .button-filter{
      margin: 20px auto 0 auto;
  }
  .container-left-filters{
      width: 35%;
      padding-right: 30px;
  }
  .container-right-filters{
      width: 65%;
  }
  .d-flex{
      display: flex;
      flex-wrap: wrap;
  }
  .container-guiasp{
      display: grid;
      grid-template-rows: 170px 1fr;
      width: 100%;
  }
