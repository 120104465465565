@import url(./variables.css);
@media screen and (max-width:1600px) {

    .container-left-filters {
        width: 40%;
    }
    .container-right-filters {
        width: 60%;
    }
    .container-tire {
       width: 33.3%;
    }
    .on-quoter > .container-tire {
        width: 50% !important;
    }
    .container-page.cart {
        width: 70%;
    }
}

@media screen and (max-width:1366px) {
    .tire-home img{
        width: 1024px;
        margin: 0 auto;
    }
    .container-page{
        width: 80%;
    }
    .container-left-filters {
        width: 40%;
    }
    .container-right-filters {
        width: 60%;
    }
    .container-tire{
        width: 33%;
    }
    .container-videos{
        width: 33%;
    }
}
@media screen and (max-width:1280px) {
    .container-buttons {
        width: 50%;
        margin-top: 15vh;
        margin-left: 31%;
    }
    .container-tire{
        width: 50%;
    }
    
    .aplications .img-aplications {
        width: 25%;
    }
    .content-cotizar{
        flex-direction: column;
        align-items: center;
    }

    .content-tire-cotizador.black {
        width: 600px;
    }
    .content-list-tires, .content-prices{
        width: 90%;
    }
    .content-prices {
        margin-left: 0;
        display: flex;
        align-items: flex-end;
    }
    .container-tire-cotizador{
        width: 100%;
    }
    .content-tire-cotizador.black {
        max-width: calc(100% - 160px);
    }
    .filter-content {
        width: 40%;
    }
    .tires-content {
        width: 60%;
    }
    .padding-left {
        padding-right: 2%;
        padding-left: 0;
    }
    .container-tips{
        width: 50%;
        margin-right: 0;
        padding: 0 5px;
    }
    .container-buttons {
        width: 60%;
        margin-top: 15vh;
        margin-left: 28%;
    }
    .container-page.cart {
        width: 90%;
    }
}

@media screen and (max-width:1055px) {
    .container-videos{
        width: 50%;
    }
    .tire-home img{
        width: 96%;
        margin: 0 auto;
    }
    .aplications .img-aplications {
        width: 23%;
        margin: 0 1%;
    }
    .modal-information {
        width: 96%;
        padding: 0 20px 30px 20px;
    }
    .filter-content {
        width: 35%;
    }
    .tires-content {
        width: 65%;
    }
    .filter-content {
        width: 45%;
    }
    .tires-content {
        width: 55%;
    }
    .icon-option:link, .icon-option:visited {
        margin-right: 0;
        width: 50%;
        padding: 35px 0;
        justify-content: center;
    }
    .container-options {
        flex-wrap: wrap;
        z-index: 5;
    }
    .container-options {
        justify-content: center;
    }
    .tire-home{
        opacity: 0.4;
    }
    .icon-option img {
        width: 40px;
    }
}

@media screen and (max-width:990px) {
    .container-buttons {
        width: 80%;
        margin-top: 15vh;
        margin-left: 10%;
        position: relative;
    }
    .tire-home-vertical {
        height: 40vh;
        position: fixed;
        justify-content: flex-start;
    }
    
    .filter-content {
        width: 40%;
    }
    .tires-content {
        width: 60%;
    }
    .content-modal-button.justify-content-between{
        flex-direction: column-reverse;
    }
    .content-modal-button.justify-content-between > button{
        width: 100%;
        margin-bottom: 15px;
    }
    .content-list-tires, .content-prices {
        width: 100%;
    }
}
@media screen and (max-width:927px) {
    .content-modal-button.justify-content-between{
        flex-direction: row;
    }
    .content-modal-button.justify-content-between > button{
        width: auto;
        margin-bottom: 15px;
    }
    .container-buttons .button-home {
        width: 40%;
    }
}
@media screen and (max-width:844px) {
    .modal-information .row{
        display: flex;
        flex-direction: column-reverse;
    }
    .modal-information .row .col-12{
        width: 100%;
    }
    .filter-content {
        width: 100%;
    }
    .tires-content {
        width: 100%;
    }
    .container-tips{
        padding: 7px;
    }
    .container-page{
        width: 96%;
        left:2%;
        overflow-y: auto;
        padding: 0 5px;
        top: 75px;
    }
    .container-page.cart {
        width: 96%;
        top: 75px;
        grid-template-columns: 100%;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
    }
    .container-button-bottom {
        display: flex;
        flex-direction: column;
    }
    .container-cart {
        width: 100%;
        height: unset;
        position: relative;
    }
    .container-information {
       overflow-y: unset;
    }
    .container-up {
        overflow-y: unset;
    }
    .container-page.cart .container-information .container-cart {
        padding: 20px 0 !important;
    }
    .content-tire-cotizador.black {
        width: 100%;
        max-width: unset;
    }
    .header-form{
        background: var(--color-white);
    }
    .button-filter {
        max-width: 100%;
        min-width: 100%;
    }
    .modal-background {
        position: fixed;
    }
    .modal-filter {
        min-height: 80%;
        max-height: 80%;
    }
    .modal-filter .footer-modal, .modal-filter .header-modal, .modal-filter .body-modal {
        padding: 0 20px;
    }
    .container-pdf {
        overflow-x: auto;
    }
    .container-pdf .pdf-info {
        min-width: 150px;
    }
    .container-pdf .pdf-info.active::after{
        display: none
    }
    .footer-filters{
        margin-bottom: 30px;
    }
    .container-left-filters {
        width: 100%;
        padding-right: 0;
    }
    .container-right-filters {
        width: 100%;
    }
}
@media screen and (max-width:600px) {
    .modal-filter {
        width: 90%;
        padding: 20px 0;
    }
    .img-tire {
        width: 150px;
    }
    .container-tire-cotizador{
        padding: 0;
    }
    .cotizador-close {
        top: 0px;
        right: 0;
    }
    .container-tips{
        width: 100%;
        padding: 0 7px;
    }
    .content-list-tires, .content-prices{
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }
    .container-information {
        padding: 0 15px 10px 15px;
    }
    .container-tire-cotizador .img-tire {
        width: 140px;
    }
    .container-videos{
        width: 20%;
        max-width: 100%;
        min-width: 100%;
        display: flex;
        flex-direction: column;
        margin-right: 10px;
        margin-bottom: 20px;
    }
    .btn-action.gray-darker{
        min-width: unset;
        font-size: 11px;
    }
    .header-callaction {
        display: flex;
        justify-content: center;
    }
    .header-callaction.options{
        width: 100%;
    }
}
@media screen and (max-width:576px) {
    .container-tires{
        width: 100%;
        justify-content: center;
    }
    .container-buttons {
        width: 90%;
        margin-top: 12vh;
        margin-left: 5%;
    }
    .container-tires {
        margin: 0 auto;
    }
    .header-form {
        padding: 15px 10px 10px 10px;
    }
    .header-form.options{
        flex-direction: column;
        align-items: flex-start;
    }
    .header-form.options .btn-header {
        min-width: 20%;
        position: relative;
        z-index: 5;
    }
    .header-callaction{
        position: relative;
    }
    .close-header.options {
        width: 40px;
        height: 40px;
        padding: 0 10px;
        border-radius: 100%;
        margin-left: unset;
        position: absolute;
        right: 0;
        top: -50px;
    }
    .close-header.options img{
        width: 100%;
    }
    .container-page.options {
        grid-template-rows: 120px 1fr;
    }
    .container-tips {
        max-width: 100%;
        margin-right: 0;
    }
}
@media screen and (max-width:480px) {
    .container-download-buttons{
        width: 100%;
    }
    .button-filter > button {
        font-size: 16px;
    }
    .container-tire{
        width: 100%;
    }
    .content-modal-button .btn-action {
        margin: 0 5px;
    }
    .container-tire-cotizador {
        flex-direction: column;
        position: relative;
    }
    .container-tire-cotizador .img-tire {
        width: 180px;
        margin-top: 15px;
    }
    .content-modal-button.justify-content-between{
        flex-direction: column-reverse;
    }
    .content-modal-button.justify-content-between > button{
        width: 100%;
        margin-bottom: 15px;
    }
    header .btn-action span{
        display: none;
    }
    #menu-page {
        position: relative;
        width: 45px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 200;
    }
    header .iconhome {
        width: 170px;
    }
    header .content-buttons {
        padding: 5px 0;
    }
    .btn-close-cotizador {
        width: 88px;
        right: 99px;
    }
    .flag {
        display: none;
    }
    .quoter-height{
        max-height:  unset !important;
    }
}

