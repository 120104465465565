:root{
    --color-black: #020001;
    --color-black-transparent:rgba(22,22,22,0.6);
    --color-white: #FFF;
    --color-gray:#dcdcdc;
    --color-gray-darker:#b6b6b6;
    --color-gray-dark:#2D2D2D;
    --color-yellow: #FFA400;
    --color-red:#C00A27;
    --color-blue:#2E2C94;
    --color-green:#cbd300;
    --font-bold: "ContinentalStagSans-Med", Helvetica, Sans-serif;
    --font-normal: "ContinentalStagSans-Book", Helvetica, Sans-serif;
    --font-light: "ContinentalStagSans-Light", Helvetica, Sans-serif;
    --size-huge:28px;
    --size-big:18px;
    --size-normal:16px;
    --size-small:14px;
}

@font-face{
    font-family: "ContinentalStagSans-Med";
    src: local("ContinentalStagSans-Med"),
    local("ContinentalStagSans-Med"),
    url("./fonts/ContinentalStagSans-Med.otf");
    font-weight: bold;
}
@font-face{
    font-family:"ContinentalStagSans-Book";
    src: local("ContinentalStagSans-Book"),
    local("ContinentalStagSans-Book"),
    url("./fonts/ContinentalStagSans-Book.otf");
    font-weight: normal;
}
@font-face{
    font-family: "ContinentalStagSans-Light";
    src: local("ContinentalStagSans-Light"),
    local("ContinentalStagSans-Light"),
    url("./fonts/ContinentalStagSans-Light.otf");
    font-weight: lighter;
}

@media screen and (max-width:960px) {
    :root{
        --size-huge:24px;
        --size-big:16px;
        --size-normal:14px;
        --size-small:15px;
    }
}

.no-gutters{
    margin-left: 0;
    margin-right: 0;
}
.w-100{
    width: 100% !important;
}
.color-green{
    color: var(--color-green);
}
.color-red{
    color: var(--color-red);
}