@import url(./variables.css);
@import url(./elements.css);
body{
    font-family: var(--font-normal);
    background: var(--color-black);
}
/* header */
header{
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px 15px 20px;
    z-index: 100;
}

header .iconhome{
    width: 160px;
}

header .content-buttons{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 5px 15px;
}
header .content-buttons .iconcart{
    position: relative;
    width: 30px;
}
header .content-buttons .iconcart .numbercart{
    position: absolute;
    top: -9px;
    left: 2px;
    background: var(--color-yellow);
    color:var(--color-black);
    font-size: 11px;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font-bold);
}
#menu-page{
    position: relative;
    width: 90px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 200;
    cursor: pointer;
}
#menu-page span{
    position: relative;
    width: 5px;
    height: 5px;
    border-radius: 100%;
    background: var(--color-white);
    transition: 0.3s;
}
#menu-page span::after, #menu-page span::before{
    content: '';
    width: 5px;
    height: 5px;
    border-radius: 100%;
    background: var(--color-white);
    position: absolute;
    left: 0;
    transition: 0.3s all;
}
#menu-page span::after{
    top:-10px
}
#menu-page span::before{
    top:10px
}
#menu-page.active span{
    background: transparent;
}
#menu-page.active span::after, #menu-page.active span::before{
    content: '';
    border-radius: 7px;
    background: var(--color-white);
    position: absolute;
    left: 0;
    width: 30px;
    height: 4px;
}
#menu-page.active span::after{
    top:0px;
    left: -21px;
    transform: rotate(45deg);
}
#menu-page.active span::before{
    top:0px;
    left: -21px;
    transform: rotate(-45deg);
}
.container-menu{
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    opacity: 0;
    pointer-events: none;
    transition: 0.3s all;
}
.container-menu.active{
    opacity: 1;
    pointer-events:all;
    
}
.menu-continental{
    background: var(--color-gray-dark);
    color: var(--color-white);
    width: 264px;
    height: 100%;
    margin-right: -264px;
    transition: 0.3s all;
}
.menu-continental.active{
    margin-right: 0;
    
}
 .button-init{
    padding: 8px 15px;
    display: flex;
    align-items: center;
    justify-content: center;

}
.button-init img{
    width: 20px;
    margin-left: 7px;
}
.button-init.small-icon img{
    width: 10px;
}
/* home */
.content-header{
    color: var(--color-white);
    font-family: var(--font-normal);
    
}
.content-header p{
    font-size: 35px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 32px
}
.content-header .medium{
    font-family: var(--font-bold);
}
.content-header .medium .color-yellow{
    color: var(--color-yellow);
}
.content-header .font-light{
    font-family: var(--font-light);
    font-size: 34px;
}
.login-continer{
    width: 100%;
    height: 100vh;
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;
    z-index: 50;
}
.login-form{
    width: 370px;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    border-radius: 17px;
}
#login-form.inactive{
    display: none;
}
#register-form.inactive{
    display: none;
}
.login-form h1{
    width: 100%;
    font-family:var(--font-light);
    font-size: var(--size-huge);
    display: block;
    text-align: center;
}
.container-form{
    width: 100%;
    display: grid;
    margin-top: 30px;
}
.icon-password{
    width: 15px;
    position: absolute;
    top: 12px;
    right: 15px;
}
.forgot-container{
    text-align: right;
}
.button-forgot, .button-forgot:link, .button-forgot:visited{
    font-size: var(--size-normal);
    font-family:var(--font-light);
    color:var(--color-white)
}

.button-forgot:hover{
    text-decoration: underline;
}
.container-switch{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;
}
.container-switch span.label-switch{
    font-family:var(--font-light);
    margin-left: 15px;
    color: var(--color-white);
}
.header-form{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 100;
    padding: 10px 20px 10px 20px;
}
.header-form.no-padding{
    padding: 15px 0 10px 0;
}
.header-form.header-black{
    background: var(--color-black);
    color: var(--color-white);
    padding: 10px 20px;
    border-radius: 20px 20px 0 0;
    
}
.header-callaction{
    display: flex;
    justify-content: flex-end;
}
.header-callaction.search-products input{
    border-radius: 20px 0 0 20px;
}
.close-header{
    width: 98px;
    padding: 0 15px 0 65px;
    border-radius: 0 20px 20px 0;
}
.header-callaction.search-products .close-header {
    width: 50px;
    padding: 0 15px 0 15px;
}
.close-header.close-al{
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius:100%;
}
.content-modal-black{
    background: var(--color-white);
    padding: 10px 20px;
    border-radius: 0 0 20px 20px;
}
.content-modal-black p{
    margin: 25px 0 20px 0;
}
.header-form.align-items-start{
    align-items: flex-start;
}
.tire-home{
    width: 100%;
    height: 100%;
    position: fixed;
    margin: 0 auto;
    bottom: 0;
    left: 0;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.tire-home img{
    width: auto;
    height: 100vh;
    margin: 0 auto;
}
.tire-home-vertical{
    height: calc(100vh - 70px);
    position: fixed;
    margin: 0 auto;
    bottom: 0;
    left: 0;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.tire-home-vertical img{
    height: 100%;
}
.filter-content{
    width: 30%;
}
.tires-content{
    width: 70%;
}
/* home */
.container-home{
    width: 100vw;
    height: 100vh;
    position: relative;
    display: grid;
    grid-template-rows: 50% 50%;
}
.section-black{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    overflow: hidden;
    position: relative;
}
.section-yellow{
    background: var(--color-yellow);
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    overflow: hidden;
    position: relative;
}
.tire-home{
    position: absolute;
    z-index: 1;
    width: 20%;
    min-width: 350px; 
    left: 5%;
    display: flex;
}
.tire-home.up{
    top: 18%;
    align-items: flex-start;
}
.tire-home.down{
    bottom: 12%;
    align-items: flex-end;
}
.backgradient{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
}
.backgradient.yellow{
    background: rgb(255,159,0);
    background: linear-gradient(180deg, rgba(255,159,0,0.36738445378151263) 14%, rgba(255,159,0,0.1) 100%);

}
.backgradient.black{
    background: rgb(6,6,6);
    background: linear-gradient(0deg, rgba(6,6,6,0.85) 14%, rgba(0,0,0,0.3) 100%);
}
.container-options{
    display: flex;
    align-items: center;
    position: relative;
    z-index: 5;
}
.icon-option:link, .icon-option:visited{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: var(--color-white);
    font-size: 18px;
    font-family: var(--font-bold);
    margin-right: 50px;
    width: 205px;
    padding: 35px 0;
}
.icon-option:hover{
    color: var(--color-yellow);
}
.icon-option.black{
    color: var(--color-black);
}
.icon-option.black:hover{
    color: var(--color-white);
}
.icon-option img{
    width: 65px;
    margin-right: 15px;
}
.container-page{
    width: 70%;
    height: calc(100vh - 120px);
    background: var(--color-white);
    position: absolute;
    right: 20px;
    top: 100px;
    border-radius: 20px;
    overflow-y: auto;
    padding: 0;
    display: grid;
    grid-template-rows: 70px 1fr;
    overflow: hidden;
    z-index: 20;
}
.container-page.cart{
    width: 60%;
    top: 74px;
    grid-template-columns: 50% 50%;
    grid-template-rows: 1fr;
}
.container-information{
    margin-top: 10px;
    max-height: 97%;
    overflow-y: auto;
    padding: 0 30px 10px 30px;
    width: 100%;
    position: relative;
}
.container-page.cart .container-information .container-cart{
    padding: 20px !important;
}
.txt-2columns{
    padding: 0 15px;
}
.txt-2columns p{
    margin-bottom: 0;
}

.bottom-information{
    padding-bottom: 50px;
}
.aplications{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}
.aplications.justify-content-start{
    justify-content: flex-start;
}
.aplications .img-aplications{
    background: var(--color-gray);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
    margin: 0 10px;
}
.aplications.justify-content-start .img-aplications{
    margin: 0 10px 0 0;
}
.aplications .img-aplications img{
    width: 100%;
}
.aplications .img-aplications.img-aplications-tires{
    width: 40px;
}
.img-truck{
    width: 100%;
    position: relative;
    padding-bottom: 20px;
}
.selector-truck{
    width: 10%;
    position: absolute;
    bottom: 4px;
    left: 11%;
}
.container-tires{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
}
.on-quoter > .container-tire {
    width: 33.33% !important;
}
.ContiTips{
    align-items: unset;
}
.conti-tips{
    flex-direction: column;
}
.conti-tips .img-tip{
    width: 100%;
    border-radius: 20px 20px 0 0;
}
.conti-tips .img-tip img{
    width: 100%;
}
.conti-tips .content-tip-text{
    border-radius: 0 0 20px 20px;
}
.conti-tips .content-tip-text .information{
    min-height: 80px;
}
.title-continews{
    width: 100%;
    background: var(--color-yellow);
    color: var(--color-black);
    font-family: var(--font-bold);
    padding:10px 20px;
}
.chat{
    position: fixed;
    z-index: 100;
    background: var(--color-yellow);
    color:var(--color-black);
    padding: 10px 35px;
    border-radius: 15px 30px 0 0;
    bottom: 0;
    right: 5%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    box-shadow: 0 -4px 10px #00000057;;
}
.chat .envelope{
    width: 30px;
}
.chat span{
    margin: 3px 15px 0 15px;
}
.chat .jivochat{
    width: 70px;
}
.button-menu{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 25px;
    padding: 10px 20px;
}
.button-menu:hover{
    background: var(--color-gray-dark);
    transition: 0.3s all;
}
.button-menu img{
    width: 28px;
}
.button-menu span{
    color:var(--color-white);
    font-family: var(--font-light);
    margin-top: 5px;
    margin-left: 15px;
}
.measure{
    display: none;
}
.text-small{
    font-size: 13px !important;
}
.news-container{
    display: flex;
    flex-wrap: wrap;
}
.content-input{
    width: 100%;
    position: relative;
}
.bnt-buscar{
    width: 120px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background:var(--color-yellow);
    font-size: 16px;
    font-family: var(--font-bold);
    text-align: center;
    border-radius: 25px;
}
.margintop-10{
    margin-top: 10px;
}
.container-cart{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.container-services{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
.container-download-buttons{
    width: 400px;
    margin: 0 auto;
}
.btn-close-cotizador {
    width: 87px;
    position: absolute;
    right: 239px;
    top: 14px;
    z-index: 100;
}
.just-backgradient.yellow{
    background: rgb(255,159,0);
    padding-top: 10%;
    height: 50%;

}
.just-backgradient.yellow .button-menu span{
    color: var(--color-black);
}
.just-backgradient.yellow .button-menu:hover span{
    color: var(--color-white);
}
.just-backgradient.yellow .button-menu:hover img{
    filter: invert(1);
}
.just-backgradient.black{
    padding-top: 35%;
    height: 50%;
    background: rgb(6,6,6);
}
.input-cotizador{
    border-radius: 0px;
    padding: 7px 0;
    font-family: var(--font-normal);
    font-size: var(--size-normal);
}
.input-cotizador:focus{
    outline: none;
    box-shadow: 0px 0px 1px var(--color-gray-dark);
}
size-information{
    width: 100%;
    font-size: 12px;
    background: var(--color-gray-darker);
    color: var(--color-black);
    font-family: var(--font-light);
    text-align: center;
    padding: 20px 5px 5px 5px;
    border-radius: 0 0 20px 20px;
    margin-top: -15px;
}
.flag {
    position: absolute;
    left: 190px;
    width: 50px;
    border-radius: 6px;
}
.lottie-icon {
    width: 100%;
}